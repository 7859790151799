import React from 'react'
import {SectionNotification as SectionNotificationTPA} from 'wix-ui-tpa'
import classNames from 'classnames'
import {useModal} from '../../../../hooks/modal'
import {classes as c} from './section-notification.st.css'
import {SectionNotificationProps} from '.'

export const SectionNotification = ({text, icon, className, size, type}: SectionNotificationProps) => {
  const {insideModal} = useModal()

  return (
    <SectionNotificationTPA size={size} type={type} className={classNames(className, {[c.insideModal]: insideModal})}>
      {icon ? <SectionNotificationTPA.Icon icon={icon} /> : null}
      <SectionNotificationTPA.Text>{text}</SectionNotificationTPA.Text>
    </SectionNotificationTPA>
  )
}
