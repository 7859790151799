import React from 'react'
import {SectionNotificationSize, SectionNotificationType} from 'wix-ui-tpa'
import classNames from 'classnames'
import {Error} from '../../../../../icons/Error'
import {SectionNotification} from '../schedule/item/section-notification'
import {UpdatedIndication} from '../schedule/item/updated-indication'
import {useModal} from '../../hooks/modal'
import s from './indications.scss'
import {IndicationsProps} from '.'

export const Indications = ({canceled, t, formattedUpdatedDate, noTopMargin = false}: IndicationsProps) => {
  const {insideModal} = useModal()
  const shouldUpdatedIndicationShow = !canceled && formattedUpdatedDate

  return (
    <>
      {canceled ? (
        <SectionNotification
          size={SectionNotificationSize.compact}
          type={SectionNotificationType.wired}
          className={classNames(s.cancelNotification, {[s.insideModal]: insideModal, [s.noTopMargin]: noTopMargin})}
          icon={<Error className={s.canceledIcon} />}
          text={t('itemCanceled')}
        />
      ) : null}
      {shouldUpdatedIndicationShow ? <UpdatedIndication t={t} formattedUpdatedDate={formattedUpdatedDate} /> : null}
    </>
  )
}
