import React from 'react'
import {
  getScheduleItemModalItem,
  isScheduleItemModalOpen,
  isFiltersModalOpen,
  isPersonalAgendaModalOpen,
  isLoginDrawerOpen,
} from '../../selectors/modals'
import {useWidgetProps} from '../../hooks/widget-props'
import {ScheduleItem} from './schedule-item'
import {FiltersModal} from './filters-modal/filters'
import {PersonalAgendaModal} from './personal-agenda/personal-agenda'
import {LoginDrawer} from './login-drawer'

export const Modals = () => {
  const {modals} = useWidgetProps()

  return (
    <>
      {isFiltersModalOpen(modals) && <FiltersModal />}
      {isPersonalAgendaModalOpen(modals) && <PersonalAgendaModal />}
      {isScheduleItemModalOpen(modals) && <ScheduleItem item={getScheduleItemModalItem(modals)} />}
      {isLoginDrawerOpen(modals) ? <LoginDrawer /> : null}
    </>
  )
}
